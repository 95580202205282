import get from 'lodash.get';
import getIsB2C from 'src/js/modules/config/selectors/is-b2c';

const getDashboardURL = (rootState) => {
  if (getIsB2C(rootState)) {
    return get(rootState, 'config.common.myyola.url');
  }

  return get(rootState, 'config.common.myyola.wl_url');
};

export default getDashboardURL;
