import axios from 'axios';
import i18next from 'i18next';
import config from 'src/js/modules/config';
import getCookie from 'src/js/utils/get-cookie';
import setCookie from 'src/js/utils/set-cookie';
import actions from '../actions';
import constants from '../constants';
import getLocaleFromUrl from '../helpers/get-locale-from-url';

const loadTranslations = () => async (dispatch, getState) => {
  const state = getState();
  const localeCookie = config.selectors.getLocaleCookie(state);
  const supportedLocales = config.selectors.getBaseLanguageCodes(state);
  const currentDomain = config.selectors.getCurrentDomain(state);
  const localeFromURL = getLocaleFromUrl();

  if (localeCookie && localeFromURL && supportedLocales.includes(localeFromURL)) {
    setCookie(localeCookie, localeFromURL, currentDomain);
  }

  const localeFromCookie = getCookie(localeCookie);
  const locale = localeFromCookie || constants.DEFAULT_LOCALE;

  if (i18next.hasResourceBundle(locale, constants.DEFAULT_I18NEXT_NAMESPACE)) {
    dispatch(actions.setLocale(locale));
    await i18next.changeLanguage(locale);
    return;
  }

  try {
    const response = await axios.get(`/locale/${locale}.json`);
    i18next.addResourceBundle(
      locale,
      constants.DEFAULT_I18NEXT_NAMESPACE,
      response.data,
      true,
      true
    );
    dispatch(actions.setLocale(locale));
    await i18next.changeLanguage(locale);
  } catch (e) {
    const response = await axios.get(`/locale/${constants.DEFAULT_LOCALE}.json`);
    i18next.addResourceBundle(
      constants.DEFAULT_LOCALE,
      constants.DEFAULT_I18NEXT_NAMESPACE,
      response.data,
      true,
      true
    );
    dispatch(actions.setLocale(constants.DEFAULT_LOCALE));
    await i18next.changeLanguage(constants.DEFAULT_LOCALE);
  }
};

export default loadTranslations;
