import constants from '../constants';

const getLocaleFromUrl = () => {
  const { pathname } = window.location;

  return (
    pathname.match(constants.LANG_REGEX) &&
    pathname.match(constants.LANG_REGEX)[0].replace(/\//g, '')
  );
};

export default getLocaleFromUrl;
