import React from 'react';
import { useSelector } from 'react-redux';
import OverlayLoader from '../components/overlay-loader';
import getMessage from '../selectors/message';

const OverlayLoaderContainer = () => {
  const message = useSelector(getMessage);

  return <OverlayLoader message={message} hidden={!message} />;
};

export default OverlayLoaderContainer;
