import yousersjs from '@yola/yousersjs';
import i18n from 'src/js/modules/i18n';
import partner from 'src/js/modules/partner';

const register = (userData) => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    const state = getState();
    const locale = i18n.selectors.getLocale(state);
    const csrfInstance = yousersjs.csrfInit();
    let token;

    try {
      token = await csrfInstance.get();
    } catch (error) {
      console.error('Failed to get csrf token', error);
      reject(error.response);
    }

    const data = {
      ...userData,
      locale,
      csrfmiddlewaretoken: token,
      partner_id: partner.accessors.getPartnerId(),
    };

    try {
      await yousersjs.requests.register(data);
      resolve();
    } catch (error) {
      console.error('Failed to register user', error);
      reject(error.response);
    }
  });

export default register;
