import store from 'src/js/store';
import getAvailablePlatformComponents from '../selectors/available-platform-components';
import platformComponents from '../constants/platform-components';

const isAppAllowedByPartner = () => {
  const state = store.getState();
  const availablePlatformComponents = getAvailablePlatformComponents(state);

  return availablePlatformComponents.includes(platformComponents.USER_AUTH);
};

export default isAppAllowedByPartner;
