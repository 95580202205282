import yousers from '@yola/yousersjs';
import store from 'src/js/store';
import config from 'src/js/modules/config';

const configureYousersJS = () => {
  const apiURL = config.selectors.getApiUrl(store.getState());
  yousers.setAPIRoot(apiURL);
};

export default configureYousersJS;
