import getCookieDomain from './cookie-domain';
import getWLCookieDomain from './wl-cookie-domain';
import getIsB2C from './is-b2c';

const getCurrentDomain = (rootState) => {
  if (getIsB2C(rootState)) {
    return getCookieDomain(rootState);
  }
  return getWLCookieDomain(rootState);
};

export default getCurrentDomain;
