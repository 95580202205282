import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Paragraph, ListItem, List } = designSystem;

const Footer = (props) => {
  const { captions, links } = props;

  return (
    <footer className="ws-footer">
      <div className="ws-page-container ws-page-container--small">
        <Paragraph size="small" appearance="medium-emphasis">
          {captions.copyright}
        </Paragraph>

        <div className="ws-footer__copyright-links">
          <List>
            {links.map((link) => (
              <ListItem key={Date.now() + link.title} size="small" appearance="accent">
                <a href={link.href}>{link.title}</a>
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  captions: PropTypes.shape({
    copyright: PropTypes.string.isRequired,
  }).isRequired,
  links: PropTypes.array,
};

Footer.defaultProps = {
  links: [],
};

export default Footer;
