import config from 'src/js/modules/config';
import store from 'src/js/store';

/**
 * we need this hack, since safari in some cases works incorrectly with cookies
 * after server response. After logout, `Yola` cookie still exists from js, but it shouldn't,
 * since server removes it.
 */

const clearYolaCookie = () => {
  const state = store.getState();
  const isB2C = config.selectors.getIsB2C(state);
  const domain = isB2C
    ? config.selectors.getCookieDomain(state)
    : config.selectors.getWLCookieDomain(state);

  document.cookie = `Yola=;path=/;domain=${domain};expires=expires=Thu, 18 Dec 2013 12:00:00 UTC;`;
};

export default clearYolaCookie;
