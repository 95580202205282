import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import config from 'src/js/modules/config';

const TagManagerContainer = () => {
  const location = useLocation();
  const { pathname } = location;

  const { dataLayerName } = useSelector(config.selectors.getGTM);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        page: pathname,
      },
      dataLayerName,
    });
  }, [pathname, dataLayerName]);

  return null;
};

export default TagManagerContainer;
