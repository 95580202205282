import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';
import config from 'src/js/modules/config';
import user from 'src/js/modules/user';
import getSearchParams from 'src/js/utils/get-search-params';
import Page from 'src/js/common/components/page';

const { Heading, Stack, ActionButton } = designSystem;

function EmailConfirmationContainer() {
  const token = useMemo(() => {
    const searchParams = getSearchParams();
    return searchParams.get('code');
  }, []);
  const [confirmed, setConfirmed] = useState(false);
  const [tokenChecked, setTokenChecked] = useState(!token);
  const dashboardURL = useSelector(config.selectors.getDashboardURL);

  const captions = {
    title: {
      confirmed: i18next.t('Now your email is confirmed'),
      expired: i18next.t('Confirmation link expired'),
    },
    button: {
      dashboard: i18next.t('Go to dashboard'),
    },
  };

  useEffect(() => {
    if (!tokenChecked) {
      user.operations
        .verifyConfirmationToken({ token })
        .then(() => {
          setTokenChecked(true);
          setConfirmed(true);
        })
        .catch((error) => {
          console.error('Failed to verify token', error);
          setTokenChecked(true);
        });
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  if (!tokenChecked) return null;

  const title = confirmed ? captions.title.confirmed : captions.title.expired;

  return (
    <Page footer={false} className="ws-page--email-confirmation">
      <Stack gap="spacing-m">
        <Heading type="heading-2" align="center">
          {title}
        </Heading>

        <ActionButton
          appearance="accent"
          format="solid"
          size="large"
          href={dashboardURL}
          label={captions.button.dashboard}
        />
      </Stack>
    </Page>
  );
}

export default EmailConfirmationContainer;
