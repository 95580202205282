import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routesMap from 'src/js/router/helpers/routes-map';
import PageTitleContainer from 'src/js/common/containers/page-title-container';
import SignupContainer from 'src/js/pages/containers/signup-container';
import LoginContainer from 'src/js/pages/containers/login-container';
import ForgotPasswordContainer from 'src/js/pages/containers/forgot-password-container';
import ResetPasswordContainer from 'src/js/pages/containers/reset-password-container';
import LogoutContainer from 'src/js/pages/containers/logout-container';
import EmailConfirmationContainer from 'src/js/pages/containers/email-confirmation-container';
import OverlayLoaderContainer from 'src/js/modules/overlay-loader/containers/overlay-loader-container';
import TagManagerContainer from 'src/js/modules/analytics/tag-manager/containers/tag-manager-container';
import config from './modules/config';
import i18n from './modules/i18n';

function App() {
  const configData = useSelector(config.selectors.getConfig);
  const locale = useSelector(i18n.selectors.getLocale);
  if (!configData || !locale) return null;

  return (
    <React.Fragment>
      <Switch>
        <Route path={routesMap.signup.pattern()} component={SignupContainer} />
        <Route path={routesMap.resetPassword.pattern()} component={ResetPasswordContainer} />
        <Route path={routesMap.forgotPassword.pattern()} component={ForgotPasswordContainer} />
        <Route path={routesMap.logout.pattern()} component={LogoutContainer} />
        <Route
          path={routesMap.emailConfirmation.pattern()}
          component={EmailConfirmationContainer}
        />
        <Route path={routesMap.index.pattern()} component={LoginContainer} />
      </Switch>
      <PageTitleContainer />
      <OverlayLoaderContainer />
      <TagManagerContainer />
    </React.Fragment>
  );
}

export default App;
