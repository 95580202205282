import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import config from './modules/config';
import i18n from './modules/i18n';
import partner from './modules/partner';
import overlayLoader from './modules/overlay-loader';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    locale: i18n.reducers.locale,
    config: config.reducers.serviceConfig,
    partner: partner.reducers.partnerData,
    ui: combineReducers({
      overlayLoader: combineReducers({
        message: overlayLoader.reducers.message,
      }),
    }),
  });

export default rootReducer;
