import axios from 'axios';
import actions from '../actions';

const fetchConfig = () => async (dispatch) => {
  try {
    const config = await axios('/config.json');
    dispatch(actions.setServiceConfig(config.data));
  } catch (e) {
    console.error('Failed to load config', e);
  }
};

export default fetchConfig;
