import * as Sentry from '@sentry/browser';

let sentryIsInitialized = false;

const init = (dsn) => {
  Sentry.init({
    dsn,
  });
  sentryIsInitialized = true;
};
const isInit = () => sentryIsInitialized;
const getInstance = () => Sentry;

export default {
  init,
  getInstance,
  isInit,
};
