import React from 'react';

const CircleLoader = () => (
  <div className="ws-overlay-loader">
    <svg className="ws-overlay-loader__spinner ws-overlay-loader__spinner--big" viewBox="0 0 50 50">
      <circle
        className="ws-overlay-loader__circle"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="4"
      />
    </svg>
    <svg
      className="ws-overlay-loader__spinner ws-overlay-loader__spinner--small"
      viewBox="0 0 50 50"
    >
      <circle
        className="ws-overlay-loader__circle"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
      />
    </svg>
  </div>
);

export default CircleLoader;
