import yousersjs from '@yola/yousersjs';
import clearYolaCookie from '../helpers/clear-yola-cookie';

const logout = () =>
  yousersjs.requests.logout().then((data) => {
    clearYolaCookie();
    return data;
  });

export default logout;
