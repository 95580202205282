import React from 'react';
import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';
import Page from 'src/js/common/components/page';

const { Heading, Paragraph, Stack, Loader } = designSystem;

function LogoutProgressContainer() {
  const captions = {
    title: i18next.t('Logging out'),
    subtitle: i18next.t('Please wait while we log you out of your account'),
  };

  return (
    <Page header={false} footer={false}>
      <Stack gap="spacing-l">
        <Stack gap="spacing-3-xs">
          <Heading type="heading-2" align="center">
            {captions.title}
          </Heading>
          <Paragraph align="center" appearance="medium-emphasis">
            {captions.subtitle}
          </Paragraph>
        </Stack>
        <Loader size="medium" />
      </Stack>
    </Page>
  );
}

export default LogoutProgressContainer;
