import getSearchParamsString from 'src/js/utils/get-search-params-string';
import getLocaleFromUrl from '../../modules/i18n/helpers/get-locale-from-url';

const normalizePath = (path) => (path ? `/${path}` : '');

let localesPattern = ':locale?';
let locale = '';

export const initializeRoutesMap = (locales = []) => {
  const localeFromUrl = getLocaleFromUrl();
  locale = normalizePath(locales.includes(localeFromUrl) && localeFromUrl);
  localesPattern = `:locale(${locales.join('|')})?`;
};

export default {
  index: {
    pattern: () => `/${localesPattern}`,
    url: () => `/${locale}${getSearchParamsString()}`,
  },
  signup: {
    pattern: () => `/${localesPattern}/signup`,
    url: () => `${locale}/signup${getSearchParamsString()}`,
  },
  resetPassword: {
    pattern: () => `/${localesPattern}/reset-password`,
    url: () => `${locale}/reset-password${getSearchParamsString()}`,
  },
  forgotPassword: {
    pattern: () => `/${localesPattern}/forgot-password`,
    url: () => `${locale}/forgot-password${getSearchParamsString()}`,
  },
  logout: {
    pattern: () => `/${localesPattern}/logout`,
    url: () => `${locale}/logout${getSearchParamsString()}`,
  },
  emailConfirmation: {
    pattern: () => `/${localesPattern}/email-confirmation`,
    url: () => `${locale}/email-confirmation${getSearchParamsString()}`,
  },
};
