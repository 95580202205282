import React from 'react';
import PropTypes from 'prop-types';
import HeaderContainer from '../../containers/header-container';
import FooterContainer from '../../containers/footer-container';

const SplitPageView = (props) => {
  const { children, images } = props;

  return (
    <div className="ws-auth-page">
      <div className="ws-auth-page__content ws-page">
        <HeaderContainer align="left" />

        <main className="ws-page-container ws-page-container--small">
          <div className="ws-page-content">{children}</div>
        </main>

        <FooterContainer />
      </div>

      <div className="ws-auth-page__image">
        <img src={images.x1} srcSet={`${images.x1} 1x, ${images.x2} 2x`} alt="" />
      </div>
    </div>
  );
};

SplitPageView.propTypes = {
  images: PropTypes.shape({
    x1: PropTypes.string.isRequired,
    x2: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default SplitPageView;
