import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import routesMap from 'src/js/router/helpers/routes-map';

const Header = (props) => {
  const { logoSrc, logoWidth, logoHeight } = props;

  return (
    <header className="ws-header">
      <div className="ws-header__inner">
        <Link to={routesMap.index.url()} className="ws-header__logo">
          <img src={logoSrc} width={logoWidth} height={logoHeight} alt="Logo" />
        </Link>
      </div>
    </header>
  );
};

Header.propTypes = {
  logoSrc: PropTypes.string.isRequired,
  logoWidth: PropTypes.number.isRequired,
  logoHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Header.defaultProps = {
  logoHeight: 'auto',
};

export default Header;
