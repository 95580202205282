import getSearchParams from 'src/js/utils/get-search-params';
import redirectToUrl from 'src/js/utils/redirect-to-url';

const redirectToNext = () => {
  const searchParams = getSearchParams();
  if (searchParams.toString() && searchParams.has('next')) {
    const next = searchParams.get('next');
    redirectToUrl(next);
    return true;
  }

  return false;
};

export default redirectToNext;
