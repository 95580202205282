import yousersjs from '@yola/yousersjs';
import partner from 'src/js/modules/partner';

const login = (userData) => () => {
  const data = {
    ...userData,
    partner_id: partner.accessors.getPartnerId(),
  };

  return yousersjs.requests.login(data);
};

export default login;
