import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import partner from 'src/js/modules/partner';
import Footer from 'src/js/common/components/footer';

const year = new Date().getFullYear();

const FooterContainer = () => {
  const partnerName = useSelector(partner.selectors.getPartnerName);
  const privacyPolicyURL = useSelector(partner.selectors.getPrivacyPolicyURL);
  const tosURL = useSelector(partner.selectors.getTosURL);
  const dpaURL = useSelector(partner.selectors.getDpaURL);

  const footerLinks = useMemo(() => {
    const links = [];

    if (privacyPolicyURL) {
      links.push({
        href: privacyPolicyURL,
        title: i18next.t('Privacy Policy'),
      });
    }

    if (tosURL) {
      links.push({
        href: tosURL,
        title: i18next.t('Terms of Service'),
      });
    }

    if (dpaURL) {
      links.push({
        href: dpaURL,
        title: i18next.t('Data Processing'),
      });
    }

    return links;
  }, [privacyPolicyURL, tosURL, dpaURL]);

  const footerCaption = {
    copyright: i18next.t('Copyright {year} {partnerName}. All rights reserved', {
      year,
      partnerName,
    }),
  };

  return <Footer captions={footerCaption} links={footerLinks} />;
};

export default FooterContainer;
