import logout from './logout';
import sendPasswordResetTokens from './send-password-reset-tokens';
import resetPassword from './reset-password';
import verifyConfirmationToken from './verify-confirmation-token';

export default {
  logout,
  sendPasswordResetTokens,
  resetPassword,
  verifyConfirmationToken,
};
