import actionTypes from '../constants/action-types';

const message = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SHOW: {
      return action.payload.message;
    }

    case actionTypes.HIDE: {
      return null;
    }

    default:
      return state;
  }
};

export default message;
