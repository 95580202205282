import TagManager from 'react-gtm-module';

const init = ({ gtmId, auth, preview, dataLayerName }) => {
  TagManager.initialize({
    gtmId,
    auth,
    preview,
    dataLayerName,
  });
};

export default { init };
