import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Label = (props) => {
  const { id, required, children } = props;

  const classnames = classNames(
    'ws-label',
    'ws-ui-text',
    'ws-ui-text--medium',
    'ws-ui-text--high-emphasis',
    {
      'ws-label--required': required,
    }
  );

  /* eslint-disable yola/jsx-a11y/label-has-for */
  return (
    <label className={classnames} htmlFor={id}>
      {children}
    </label>
  );
};

Label.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Label.defaultProps = {
  required: false,
};

export default Label;
