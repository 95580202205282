import React, { useState } from 'react';
import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';
import user from 'src/js/modules/user';
import Label from 'src/js/common/components/label';
import Page from 'src/js/common/components/page';
import withAuthRedirect from '../hoc/with-auth-redirect';

const { Heading, Paragraph, InputGroup, Stack, ActionButton, NotificationMessage, FieldStatus } =
  designSystem;

function ForgotPasswordContainer() {
  const captions = {
    title: i18next.t('Forgot password?'),
    subtitle: i18next.t(
      `Enter the email used for your account and we'll send you a link to reset your password`
    ),
    button: {
      label: i18next.t('Reset password'),
    },
  };

  const [email, setEmail] = useState('');
  const [notification, setNotification] = useState(null);
  const [errors, setErrors] = useState(null);

  const onSubmit = async (event) => {
    event.preventDefault();
    const data = {
      email,
    };

    if (email.trim().length === 0) {
      setErrors(i18next.t('Field is required'));
      return;
    }

    setErrors(null);

    setNotification({
      appearance: 'accent',
      text: i18next.t('Please wait while we process your request...'),
    });

    user.operations
      .sendPasswordResetTokens(data)
      .then(() => {
        setNotification({
          appearance: 'success',
          text: i18next.t(
            'We have sent a confirmation code to your email. Please check your email for further instructions'
          ),
        });
      })
      .catch(() => {
        setNotification({
          appearance: 'danger',
          text: i18next.t('Please enter a valid email'),
        });
      });
  };

  return (
    <Page>
      <Stack gap="spacing-m">
        <Stack gap="spacing-3-xs">
          <Heading type="heading-2" align="center">
            {captions.title}
          </Heading>
          <Paragraph align="center" appearance="medium-emphasis">
            {captions.subtitle}
          </Paragraph>
        </Stack>

        {notification && (
          <NotificationMessage appearance={notification.appearance} title={notification.text} />
        )}

        <form onSubmit={onSubmit}>
          <Stack gap="spacing-m">
            <Stack gap="spacing-3-xs">
              <Label id="email" required>
                {i18next.t('Email')}
              </Label>
              <InputGroup
                id="email"
                name="email"
                iconGlyph="mail"
                size="large"
                type="email"
                value={email}
                invalid={Boolean(errors)}
                onChange={setEmail}
                placeholder={i18next.t('Enter your email')}
              />
              {errors && <FieldStatus appearance="error" text={errors} />}
            </Stack>

            <ActionButton
              appearance="accent"
              format="solid"
              size="large"
              type="submit"
              label={captions.button.label}
            />
          </Stack>
        </form>
      </Stack>
    </Page>
  );
}

export default withAuthRedirect(ForgotPasswordContainer);
