import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import user from 'src/js/modules/user';
import config from 'src/js/modules/config';
import redirectToUrl from 'src/js/utils/redirect-to-url';

const withAuthRedirect = (Component) => (props) => {
  const dashboardURL = useSelector(config.selectors.getDashboardURL);

  useEffect(() => {
    const isAuthenticated = user.verifiers.isAuthenticated();

    if (isAuthenticated) {
      redirectToUrl(dashboardURL);
    }
  }, []);

  return <Component {...props} />;
};

export default withAuthRedirect;
