import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import CircleLoader from '../circle-loader';

const { Paragraph } = designSystem;

const OverlayLoader = (props) => {
  const { hidden, message } = props;

  const classNames = classnames('ws-overlay', {
    'ws-overlay--hidden': hidden,
  });

  return (
    <div className={classNames}>
      <CircleLoader />
      {message && (
        <Paragraph size="large" align="center">
          {message}
        </Paragraph>
      )}
    </div>
  );
};

OverlayLoader.propTypes = {
  hidden: PropTypes.bool,
  message: PropTypes.string,
};

OverlayLoader.defaultProps = {
  hidden: true,
  message: '',
};

export default OverlayLoader;
