import React, { useMemo, useState } from 'react';
import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';
import user from 'src/js/modules/user';
import redirectToUrl from 'src/js/utils/redirect-to-url';
import routesMap from 'src/js/router/helpers/routes-map';
import Label from 'src/js/common/components/label';
import Page from 'src/js/common/components/page';
import getSearchParams from 'src/js/utils/get-search-params';
import withAuthRedirect from '../hoc/with-auth-redirect';

const { Heading, Paragraph, InputGroup, Stack, ActionButton, NotificationMessage, FieldStatus } =
  designSystem;

function ResetPasswordContainer() {
  const captions = {
    title: i18next.t('New password'),
    subtitle: i18next.t('Please choose a new password'),
    button: {
      label: i18next.t('Reset password'),
    },
  };

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [notification, setNotification] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});

  const token = useMemo(() => {
    const searchParams = getSearchParams();
    return searchParams.get('code');
  }, []);

  const showErrorBanner = (text) => {
    setNotification({
      appearance: 'danger',
      text,
    });
  };

  const validateFields = () => {
    let isValid = true;
    const errors = {};

    if (password.trim().length === 0) {
      errors.password = i18next.t('Field is required');
      isValid = false;
    }

    if (password.trim().length < 8) {
      errors.password = i18next.t('Ensure this field has at least 8 characters');
      isValid = false;
    }

    if (confirmPassword.trim().length === 0) {
      errors.confirmPassword = i18next.t('Field is required');
      isValid = false;
    }

    if (isValid && password !== confirmPassword) {
      showErrorBanner(i18next.t('These passwords do not match'));
      isValid = false;
    }

    setFieldErrors(errors);
    return isValid;
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!token) {
      showErrorBanner(
        i18next.t('Something went wrong, please try requesting another password reset')
      );
      return;
    }

    setNotification(null);

    const isValid = validateFields();
    if (!isValid) return;

    setNotification({
      appearance: 'accent',
      text: i18next.t('Please wait while we update your password'),
    });

    const data = {
      password,
      token,
    };

    user.operations
      .resetPassword(data)
      .then(() => {
        setNotification({
          appearance: 'success',
          text: i18next.t('Your password has been updated'),
        });

        redirectToUrl(routesMap.index.url());
      })
      .catch((error) => {
        let message = i18next.t('Sorry, an error occurred. Please try again');
        if (error.response.status === 404) {
          message = i18next.t('Something went wrong, please try requesting another password reset');
        }

        showErrorBanner(message);
      });
  };

  return (
    <Page>
      <Stack gap="spacing-m">
        <Stack gap="spacing-3-xs">
          <Heading type="heading-2" align="center">
            {captions.title}
          </Heading>
          <Paragraph align="center" appearance="medium-emphasis">
            {captions.subtitle}
          </Paragraph>
        </Stack>

        {notification && (
          <NotificationMessage appearance={notification.appearance} title={notification.text} />
        )}

        <form onSubmit={onSubmit}>
          <Stack gap="spacing-xs">
            <Stack gap="spacing-3-xs">
              <Label id="password" required>
                {i18next.t('Password')}
              </Label>
              <InputGroup
                id="password"
                name="password"
                iconGlyph="lock"
                size="large"
                value={password}
                type="password"
                invalid={fieldErrors.password}
                onBlur={() => {}}
                onChange={setPassword}
                onFocus={() => {}}
                placeholder={i18next.t('Enter your new password')}
              />
              {fieldErrors.password && (
                <FieldStatus appearance="error" text={fieldErrors.password} />
              )}
            </Stack>
            <Stack gap="spacing-m">
              <Stack gap="spacing-3-xs">
                <Label id="confirm_password" required>
                  {i18next.t('Confirm password')}
                </Label>
                <InputGroup
                  id="confirm_password"
                  name="confirm_password"
                  iconGlyph="lock"
                  size="large"
                  value={confirmPassword}
                  type="password"
                  invalid={fieldErrors.confirmPassword}
                  onChange={setConfirmPassword}
                  placeholder={i18next.t('Confirm your new password')}
                />
                {fieldErrors.confirmPassword && (
                  <FieldStatus appearance="error" text={fieldErrors.confirmPassword} />
                )}
              </Stack>
              <ActionButton
                appearance="accent"
                format="solid"
                size="large"
                type="submit"
                label={captions.button.label}
              />
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Page>
  );
}

export default withAuthRedirect(ResetPasswordContainer);
