import React from 'react';
import PropTypes from 'prop-types';
import HeaderContainer from '../../containers/header-container';
import FooterContainer from '../../containers/footer-container';

const Page = (props) => {
  const { children, header, footer, className } = props;

  const classNames = `ws-page ${className}`;

  return (
    <div className={classNames}>
      {header && <HeaderContainer />}

      <main className="ws-page-container ws-page-container--small">
        <div className="ws-page-content">{children}</div>
      </main>

      {footer && <FooterContainer />}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.bool,
  footer: PropTypes.bool,
  className: PropTypes.string,
};

Page.defaultProps = {
  header: true,
  footer: true,
  className: '',
};

export default Page;
