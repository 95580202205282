import yousersjs from '@yola/yousersjs';
import { snakeCaseObjectToCamelCase } from 'src/js/utils/convert-case';
import i18n from '../../i18n';
import setPartnerData from '../actions/set-partner-data';

const fetchPartnerData = (partnerId) => async (dispatch, getState) => {
  const state = getState();
  const locale = i18n.selectors.getLocale(state);

  try {
    const response = await yousersjs.partner().getById(partnerId, {
      propertiesLocale: locale,
    });

    const data = snakeCaseObjectToCamelCase(response.body);
    dispatch(setPartnerData(data));
  } catch (e) {
    console.error(e);
  }
};

export default fetchPartnerData;
