import actionTypes from '../constants/action-types';

const show = (message) => ({
  type: actionTypes.SHOW,
  payload: {
    message,
  },
});

export default show;
