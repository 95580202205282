import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';
import user from 'src/js/modules/user';
import config from 'src/js/modules/config';
import routesMap from 'src/js/router/helpers/routes-map';
import Label from 'src/js/common/components/label';
import overlayLoader from 'src/js/modules/overlay-loader';
import SplitPageView from 'src/js/common/components/split-page-view';
import redirectToNext from 'src/js/common/helpers/redirect-to-next';
import redirectToUrl from 'src/js/utils/redirect-to-url';
import withAuthRedirect from '../hoc/with-auth-redirect';

const {
  Heading,
  Paragraph,
  InputGroup,
  Stack,
  Checkbox,
  ActionButton,
  Row,
  Col,
  FieldStatus,
  NotificationMessage,
} = designSystem;

function LoginContainer() {
  const captions = {
    title: i18next.t('Welcome back'),
    subtitle: i18next.t('Please enter your details'),
    checkbox: {
      label: i18next.t('Remember me'),
    },
    button: {
      label: i18next.t('Log in'),
    },
    forgotPassword: {
      label: i18next.t('Forgot password?'),
    },
    tip: {
      text: i18next.t('Don’t have an account?'),
      link: i18next.t('Sign up!'),
    },
    loader: {
      progress: i18next.t('Logging in. Please wait...'),
      success: i18next.t('Login successful. Preparing your dashboard...'),
    },
  };

  const dispatch = useDispatch();
  const dashboardURL = useSelector(config.selectors.getDashboardURL);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  const showLoader = useCallback((msg) => dispatch(overlayLoader.actions.show(msg)), [dispatch]);
  const hideLoader = useCallback(() => dispatch(overlayLoader.actions.hide()), [dispatch]);

  const validateFields = () => {
    const errors = {};
    let isValid = true;
    if (email.trim().length === 0) {
      errors.email = i18next.t('Field is required');
      isValid = false;
    }
    if (password.trim().length === 0) {
      errors.password = i18next.t('Field is required');
      isValid = false;
    }
    setFieldErrors(errors);
    return isValid;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const data = {
      email,
      password,
    };

    if (rememberMe) {
      data.remember_me = '1';
    }

    setErrorMessage(null);
    const isValid = validateFields();
    if (!isValid) return;

    showLoader(captions.loader.progress);

    dispatch(user.thunks.login(data))
      .then(() => {
        showLoader(captions.loader.success);
        const redirectedToNext = redirectToNext();

        if (!redirectedToNext) {
          redirectToUrl(dashboardURL);
        }
      })
      .catch(() => {
        setErrorMessage(i18next.t('Invalid login or password'));
        hideLoader();
      });
  };

  return (
    <SplitPageView
      images={{
        x1: '/images/login-cover-image@1x.png',
        x2: '/images/login-cover-image@2x.png',
      }}
    >
      <Stack gap="spacing-m">
        <Stack gap="spacing-3-xs">
          <Heading type="heading-2" align="center">
            {captions.title}
          </Heading>
          <Paragraph align="center" appearance="medium-emphasis">
            {captions.subtitle}
          </Paragraph>
        </Stack>

        {errorMessage && (
          <NotificationMessage align="center" appearance="danger" title={errorMessage} />
        )}

        <form onSubmit={onSubmit}>
          <Stack gap="spacing-xs">
            <Stack gap="spacing-3-xs">
              <Label id="email" required>
                {i18next.t('Email')}
              </Label>
              <InputGroup
                id="email"
                name="email"
                iconGlyph="mail"
                size="large"
                value={email}
                invalid={Boolean(fieldErrors.email || errorMessage)}
                onChange={setEmail}
                placeholder={i18next.t('Enter your email')}
              />
              {fieldErrors.email && <FieldStatus appearance="error" text={fieldErrors.email} />}
            </Stack>
            <Stack gap="spacing-3-xs">
              <Label id="password" required>
                {i18next.t('Password')}
              </Label>
              <InputGroup
                id="password"
                name="password"
                iconGlyph="lock"
                size="large"
                type="password"
                value={password}
                invalid={Boolean(fieldErrors.password || errorMessage)}
                onChange={setPassword}
                placeholder={i18next.t('Enter your password')}
              />
              {fieldErrors.password && (
                <FieldStatus appearance="error" text={fieldErrors.password} />
              )}
            </Stack>
            <Stack gap="spacing-m">
              <Row>
                <Col>
                  <Checkbox
                    id="remember_me"
                    name="remember_me"
                    label={captions.checkbox.label}
                    value={rememberMe ? '1' : '0'}
                    isLabelBold={false}
                    onChange={setRememberMe}
                  />
                </Col>
                <Col>
                  <div className="ws-text-accent-link-modifier">
                    <Paragraph appearance="accent" size="medium" align="right">
                      <Link to={routesMap.forgotPassword.url()}>
                        {captions.forgotPassword.label}
                      </Link>
                    </Paragraph>
                  </div>
                </Col>
              </Row>

              <ActionButton
                appearance="accent"
                format="solid"
                size="large"
                type="submit"
                label={captions.button.label}
                onClick={() => {}}
              />
              <div className="ws-text-accent-link-modifier">
                <Paragraph align="center">
                  {captions.tip.text} <Link to={routesMap.signup.url()}>{captions.tip.link}</Link>
                </Paragraph>
              </div>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </SplitPageView>
  );
}

export default withAuthRedirect(LoginContainer);
