import get from 'lodash.get';
import getIsB2C from './is-b2c';

const getRecaptchaSiteKey = (rootState) => {
  if (getIsB2C(rootState)) {
    return get(rootState, 'config.common.recaptcha_v2.site_key');
  }

  return get(rootState, 'config.common.recaptcha_v2.wl.site_key');
};

export default getRecaptchaSiteKey;
