import store from 'src/js/store';
import getIsB2C from 'src/js/modules/config/selectors/is-b2c';
import constants from '../constants';
import getPartnerIdFromUrl from './get-partner-id-from-url';
import getPartnerIdFromStorage from './get-partner-id-from-storage';

const getPartnerId = () => {
  const state = store.getState();

  if (getIsB2C(state)) {
    return constants.YOLA_PARTNER_ID;
  }

  return getPartnerIdFromUrl() || getPartnerIdFromStorage();
};

export default getPartnerId;
